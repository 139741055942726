<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑组织机构':'新增组织机构'" :visible.sync="show" width="600px"
        class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="100px">

            <el-form-item label="所属医院名称" prop="">
                <el-input v-model="depInfo.name" />
            </el-form-item>





            <!-- type: "",//1公立医院 2民营医院 3直辖卫生室 4卫健委  5大民营-->
            <el-form-item label="医院类型" prop="">
                <div style="display: inline-block; padding-left: 20px;">

                    <el-radio v-model="depInfo.type" :label="1">公立医院</el-radio>
                    <el-radio v-model="depInfo.type" :label="2">民营医院</el-radio>
                    <el-radio v-model="depInfo.type" :label="3">直辖卫生室</el-radio>
                    <el-radio v-model="depInfo.type" :label="4">卫健委</el-radio>
                    <el-radio v-model="depInfo.type" :label="5">大民营</el-radio>

                </div>
            </el-form-item>


            <div style="display: flex;">


                <el-form-item label="状态" prop="">
                    <el-switch v-model="depInfo.status" :active-value="1" active-color="#5BD995" active-text="正常"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch>
                </el-form-item>


                <!-- v-if="asdassdasdasdadsa" -->
                <!-- v-if="getPurviewNew('sys:dep:addz') " -->
                <el-form-item label="有无子级" prop="">
                    <!-- has_child: "", //是否有子级：1有 2无 -->
                    <el-switch v-model="depInfo.has_child" :active-value="1" active-color="#5BD995" active-text="有"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="无" class="switchSelfBox">
                    </el-switch>
                </el-form-item>

            </div>

            <div v-if="getPurviewNew('sys:dep:termtime')">

                <el-form-item label="是否启用危废" prop="">
                    <!-- has_child: "", //是否有子级：1有 2无 -->
                    <el-switch v-model="depInfo.is_hazardous" :active-value="1" active-color="#5BD995" active-text="启用"
                        :inactive-value="0" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch>
                </el-form-item>




                <el-form-item label="联单使用时间" prop="" style="clear: both">
                    <el-date-picker v-model="depInfo.hazardous_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>





                <el-form-item label="续费开始时间" prop="" style="clear: both">
                    <el-date-picker v-model="depInfo.term_start_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>


                <el-form-item label="续费结束时间" prop="" style="clear: both">
                    <el-date-picker v-model="depInfo.term_end_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>

            </div>





            <el-form-item v-show="JSON.stringify(higher).length>2" label="上级单位" prop="">
                <el-input :value="higher.name" disabled />
            </el-form-item>








            <el-form-item label="所属卫健委" prop="">
                <el-select filterable v-model="depInfo.h_id" placeholder="请选择卫健委">
                    <el-option :label="item.name" :value="item.id" v-for="item in healthList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item>



            <el-form-item label="类别" prop="">
                <el-select filterable v-model="depInfo.cate_id" placeholder="请选择类别">
                    <el-option :label="item.name" :value="item.id" v-for="item in depcateList" :key="item.id">
                    </el-option>
                </el-select>
            </el-form-item>




            <!-- <el-form-item label="角色权限" prop=""> -->
            <!-- <el-input v-model="depInfo.auth" /> -->
            <!-- <AlonePower ref="AlonePower" style="margin-left:20px;width:85%;height: 200px;" /> -->
            <!-- </el-form-item> -->




            <!-- <el-form-item label="区域" prop="name1">
                <el-select v-model="depInfo.region_id" placeholder="请选择区域">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->


            <!-- <el-form-item label="所属城市" style="width: 100%;" class="trisection">

                <el-select v-model="depInfo.province_id" placeholder="省">
                    <el-option :label="item.name" :value="item.id" v-for="item in province" :key="item.id"></el-option>
                </el-select>

                <el-select v-show="cityList.length>0" v-model="depInfo.city_id" placeholder="市">
                    <el-option :label="item.name" :value="item.id" v-for="item in cityList" :key="item.id"></el-option>
                </el-select>

                <el-select v-show="district.length>0" v-model="depInfo.region_id" placeholder="县">
                    <el-option :label="item.name" :value="item.id" v-for="item in district" :key="item.id"></el-option>
                </el-select>

            </el-form-item> -->


            <el-form-item label="所属城市" style="width: 100%;" class="trisection">

                <!-- :options="cityOptions" -->
                <el-cascader ref="areas" :props="cascaderProps" clearable @change="handleAddressChange"
                    v-model="SeleAreas" v-if="SeleAreasShow" />

            </el-form-item>





            <el-form-item label="排序" prop="name1">
                <el-input v-model="depInfo.sort" />
            </el-form-item>








            <el-form-item label="邯郸数据对接" prop="" v-if="getPurviewNew('sys:dep:handan')">
                <!-- v-model="is_handan" @change="changeis_handan" -->
                <el-switch v-model="is_handan" :active-value="1" active-color="#5BD995" active-text="启用"
                    :inactive-value="0" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                </el-switch>

            </el-form-item>

            <el-form-item label="邯郸医院标识id" prop="" v-if="is_handan">
                <el-input v-model="depInfo.handan_hospital_id" />
            </el-form-item>

            <el-form-item label="邯郸医院激活码" prop="" v-if="is_handan">
                <el-input v-model="depInfo.handan_hospital_token" />
            </el-form-item>










        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'


    import AlonePower from '../../HealthCommission/components/AlonePower.vue'


    export default {


        components: {
            AlonePower,
        },


        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',

                is_handan: 0,



                SeleAreasShow: true,
                SeleAreas: [],
                // cityOptions: [],
                cascaderProps: {
                    // multiple: true,
                    // checkStrictly: true,
                    lazy: true,
                    lazyLoad: this.getCityList1,
                    value: "id",
                    label: "name",
                    leaf: "leaf"
                },





                depInfo: {
                    name: "",
                    type: "", //1公司 2部门
                    has_child: "2", //是否有子级：1有 2无
                    pid: "",
                    status: 1,
                    h_id: "",
                    // auth: "",
                    cate_id: "",
                    sort: "",

                    province_id: "",
                    city_id: "",
                    region_id: "",
                },

                healthList: [],
                depcateList: [],

                // province: [],
                // cityList: [],
                // district: [],


                higher: {},


                isEdit: false,


                // asdassdasdasdadsa: false,

            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate'
            ]),
        },

        mounted() {

        },

        created() {

        },

        watch: {

            show(value) {

                if (this.show) {

                    if (this.healthList.length == 0) {
                        this.gethealth()
                        setTimeout(() => {
                            this.healthList = this.HealthList
                        }, 1000)
                    }

                    if (this.depcateList.length == 0) {
                        this.getdepcate()
                        setTimeout(() => {
                            this.depcateList = this.Depcate
                        }, 1000)
                    }

                    // this.getProvinceCityDistrict()

                    // setTimeout(() => {
                    //     this.province = this.Province
                    // }, 2000)




                    // setTimeout(() => {

                    //     // this.getPurviewNew('sys:dep:addz') 

                    //     if (this.isEdit) { //编辑时 肯定显示

                    //         this.asdassdasdasdadsa = true

                    //     } else { //不是编辑时 有子级显示

                    //         this.asdassdasdasdadsa = this.getPurviewNew('sys:dep:addz')

                    //     }


                    // }, 1000)




                } else {

                    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

                    // this.$refs.AlonePower.refresh()


                    this.SeleAreas = []

                    this.isEdit = false

                    // this.asdassdasdasdadsa = false

                    this.higher = {}

                    this.depInfo = {
                        name: "",
                        type: "", //1公司 2部门
                        has_child: "2", //是否有子级：1有 2无
                        pid: "",
                        status: 1,
                        h_id: "",
                        // auth: "",
                        cate_id: "",
                        sort: "",

                        province_id: "",
                        city_id: "",
                        region_id: "",
                    }

                }
            },

            // "depInfo.province_id": {
            //     deep: true,
            //     handler: function(newVal, oldVal) {

            //         this.depInfo.city_id = ''
            //         this.depInfo.region_id = ''

            //         // this.cityList = []
            //         this.district = []

            //         if (!newVal) {
            //             return
            //         }

            //         this.getProvinceCityDistrict(newVal, 2)


            //         // setTimeout(() => {
            //         //     this.cityList = this.City
            //         //     console.log("citycitycitycitycity", this.cityList);
            //         // }, 1000)
            //     }
            // },

            // "depInfo.city_id": {
            //     deep: true,
            //     handler: function(newVal, oldVal) {

            //         this.depInfo.region_id = ''

            //         this.district = []


            //         if (!newVal) {
            //             return
            //         }

            //         this.getProvinceCityDistrict(newVal, 3)


            //         setTimeout(() => {
            //             this.district = this.District
            //             console.log("districtdistrict", this.district);
            //         }, 1000)
            //     }
            // },



            // "City": {
            //     deep: true,
            //     handler: function(newVal, oldVal) {
            //         console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@", newVal);

            //         if (this.City && this.City.length > 0) {
            //             this.cityList = this.City
            //         }


            //     }
            // },

            // "depInfo.region_id": {
            //     deep: true,
            //     handler: function(newVal, oldVal) {
            //         console.log("！！！！！！！！！！！！！！！！！！！！！", this.depInfo.region_id);
            //     }
            // },

        },

        methods: {
            // changeis_handan(e) {
            //     console.log("AAAAAAAAAAAAAAAAAAA", e);
            // },








            handleAddressChange(e) {


                this.depInfo.province_id = e[0];
                this.depInfo.city_id = e[1];
                this.depInfo.region_id = e[2];
            },
            getCityList1(node, resolve) {

                const {
                    level
                } = node;

                this.$http.get('/api/city/getChilds', {
                    pid: node.value ? node.value : node.level,
                }).then((res) => {
                    if (res.code == 200) {

                        const nodes = Array.from(
                                res.data
                            )
                            .map(item => ({
                                ...item,
                                leaf: level >= 2
                            }));

                        resolve(nodes);

                    }
                }).catch((err) => {
                    console.log(err)
                });


            },




            open() {
                this.formShow = true
                this.show = true
            },

            openChild(row) {
                this.higher = row
                this.depInfo.pid = row.id

                this.open()
            },

            edit(row) {
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", row);



                this.isEdit = true

                this.open()


                this.depInfo = {
                    ...row
                }




                this.is_handan = this.depInfo.handan_hospital_id != 0 ? 1 : 0









                this.depInfo.province_id = row.province_id
                this.depInfo.city_id = row.city_id
                this.depInfo.region_id = row.region_id

                this.SeleAreas = [
                    row.province_id,
                    row.city_id,
                    row.region_id
                ]

                this.SeleAreasShow = false
                setTimeout(() => {
                    this.SeleAreasShow = true
                }, 100)



                if (row.pid != 0) {
                    this.$http.get('/api/dep/' + row.pid).then((res) => {
                        if (res.code == 200) {
                            this.higher = res.data
                        }
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }













            },

            refresh() {

                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            save() {


                // 


                // 
                console.log(this.depInfo.is_hazardous, this.depInfo.hazardous_time);

                if (this.depInfo.is_hazardous == 1 && this.depInfo.hazardous_time == null) {
                    this.$message.error("联单开始使用时间不能为空");
                    return
                }




                // <el-form-item label="是否启用危废" prop="">
                //      <el-switch v-model="depInfo.is_hazardous" :active-value="1" active-color="#5BD995" active-text="启用"
                //          :inactive-value="0" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                //      </el-switch>
                //  </el-form-item>



                //  <el-form-item label="联单使用时间" prop="" style="clear: both">
                //      <el-date-picker v-model="depInfo.hazardous_time" type="date" format="yyyy-MM-dd"
                //          value-format="yyyy-MM-dd" placeholder="选择日期">
                //      </el-date-picker>
                //  </el-form-item>






                // var a = String(this.$refs.AlonePower.save())

                // this.depInfo.auth = a

                // console.log(this.depInfo);



                console.log(this.depInfo);

                if (this.isEdit) {

                    this.$http.put('/api/dep/' + this.depInfo.id, this.depInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                        if (res.code == 200) {

                            this.refresh()

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                } else {

                    this.$http.post('/api/dep', this.depInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code == 200) {

                            this.refresh()

                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                }

            }

        }
    }
</script>

<style scoped>
    /* .selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .el-input {
        /* border: 1px red solid !important; */
        box-sizing: content-box !important;
        /* width: calc(100% - 50px) !important; */
        /* padding-right: 150rpx; */
        /* width: 380px !important; */
        /* display: flex; */
        /* position: relative;
        left: 10px; */
    }

    /* /deep/.el-input__inner .el-input__inner {
        border: 1px yellow solid !important;
        box-sizing: content-box !important;
    } */








    /deep/ .el-input.el-input--suffix .el-input__inner {
        /* border: 1px blue solid !important; */
        box-sizing: content-box !important;
        /* width: calc(100% - 70px) !important; */
        width: 350px !important;
    }



    /deep/ .el-input.el-input--suffix {
        /* border: 1px green solid !important; */
        box-sizing: content-box !important;
        /* width: 100%; */
        width: 390px !important;
    }

    /deep/ .el-input.el-input--suffix .el-input__suffix {
        /* border: 1px aqua solid !important; */
        box-sizing: content-box !important;
        /* background-color: aqua; */

        position: absolute;
        right: -20px;
        /* left: 0; */
    }




    /*  /deep/.el-input__suffix {
        border: 1px green solid !important;
        box-sizing: content-box !important;
        position: absolute;
        right: 110px;
    } */

    /deep/.el-input .el-input__prefix {
        border: 1px red solid !important;
        box-sizing: content-box !important;
        position: absolute;
        left: 5px;
        display: none !important;
    }



    .selfInputBoxIV .el-input__suffix {
        border: 1px #ff55ff solid !important;
        box-sizing: content-box !important;
        /* right: -10px; */
    }

    /* /deep/ .el-input__inner {
        border: 1px blue solid !important;
        box-sizing: content-box !important;
         
        width: 350px !important;
    } */
</style>





<style scoped lang="scss">
    .trisection {

        // border: 1px red solid !important;
        box-sizing: content-box !important;

        width: calc(100% - 40px) !important;

        .el-select {

            // border: 1px green solid !important;
            box-sizing: content-box !important;

            // width: calc(100% - 150px) !important;


            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
                // min-width: 60px;

                width: calc(100% - 55px) !important;
                // padding-right: 10px;

            }
        }

        // .el-select:nth-child(2){
        //     border: 1px red solid !important;
        //     box-sizing: content-box !important;
        // }

        .el-select:nth-child(2),
        .el-select:nth-child(3) {

            // margin-left: -40px;

        }



    }
</style>